import React, { useEffect } from "react"
import { isIE } from 'react-device-detect';
import { navigate } from "gatsby"

const BannauLight = ({data}) => {
  useEffect(() => {
    if(!isIE){
      navigate("/");
    }
  })
  return (
    <div>
        <div style={{maxWidth: '980px', margin: '20px auto'}}>
            <h1>Ihr Browser ist veraltet.</h1>
            <p style={{marginBottom: '50px'}}>Der Internet Explorer wird nicht mehr von Windows unterstützt. Bitte nutzen Sie 
                aktuelle Alternativen (z.B. Chrome, Firefox, Edge), um diese Webseite korrekt
                darstellen zu können.
                <br/>
                Weitere Informationen zur Einstellung des Internet Explorers: <a href="https://www.microsoft.com/de-ch/microsoft-365/windows/end-of-ie-support">End of IE Support</a>
            </p>
            <p style={{marginBottom: '50px'}}>
                Altersheim Bannau<br/>
                Gaswerkstrasse 6<br/>
                8570 Weinfelden<br/><br/>
                <a href="mailto:info@bannau.ch">info@bannau.ch</a><br/>
                +41 71 622 14 11<br/>
            </p>
            <p style={{marginBottom: '50px'}}>
                <a href="/download/Covid-19-Brief-Angehoerige.pdf">Covid-19 Information</a><br/>
                <a href="/download/Gesundheits-Checkliste.pdf">Gesundheits-Checkliste</a><br/>
                <a href="/download/Taxordnung.pdf">Taxordnung</a><br/>
                <a href="/download/Anmeldung.pdf">Anmeldeformular</a><br/>
            </p>
        </div>
    </div>
  )  
}

export default BannauLight